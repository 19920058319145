<template>
  <section class="main">
    <div class="d-flex flex-wrap align-items-center justify-content-between">
      <div class="table-header">Создание рассылки</div>
    </div>
    <div class="mb-3">
      <label class="form-label">Тема письма</label>
      <input type="text" class="form-control" placeholder="Тема письма" v-model="mailing.subject">
    </div>
    <div class="mb-3">
      <label class="form-label">Пре-хедер</label>
      <textarea class="form-control" placeholder="Пре-хедер" v-model="mailing.preHeader" rows="4"/>
    </div>
    <div class="mb-3">
      <div class="form-group">
        <label class="form-label">Текст</label>
        <ckeditor :editor="editor" v-model="mailing.info" :config="editorConfig"></ckeditor>
      </div>
    </div>
    <div class="mb-3">
      <label class="form-label">Баннер</label>
      <input class="form-control" type="file" id="file" ref="file"/>
    </div>
    <div class="form-group">
      <label class="form-label">Выберите товары</label>
      <multiselect v-model="mailing.products" :options="this.productListNoFilter" placeholder="Выберите" label="title"
                   track-by="title" :multiple="true"></multiselect>
    </div>
    <br>
    <div>
      <button class="btn btn-success" style="float:left;" @click="send">ОТПРАВИТЬ ТОЛЬКО СЕБЕ</button>
      <button class="btn btn-warning" style="float:right;" @click="create">Отправить всем</button>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
  name: 'CreatePage',
  components: {
    Multiselect
  },
  data: function () {
    return {
      editor: ClassicEditor,
      editorConfig: {
        // The configuration of the editor.
      },
      mailing: {
        subject: '',
        preHeader: '',
        products: [],
        info: '',
        banner: null,
        type: 1
      },
    }
  },
  computed: {
    ...mapGetters(['productListNoFilter']),
  },
  async mounted() {
    this.getProductList();
  },
  methods: {
    getProductList() {
      this.$store.dispatch('getProductListNoFilter');
    },
    send() {
      this.mailing.banner = this.$refs.file.files[0] ?? null
      this.mailing.type = 0
      this.$store.dispatch('send', this.mailing)
    },
    create() {
      this.mailing.banner = this.$refs.file.files[0]
      this.mailing.type = 1
      this.$store.dispatch('createMailingBeta', { 'mailing': this.mailing })
    },
  }
}
</script>